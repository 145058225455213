const GROWTHBOOK_API_HOST =
  process.env["NEXT_PUBLIC_GROWTHBOOK_API_HOST"] ?? "";
const GROWTHBOOK_CLIENT_KEY =
  process.env["NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY"] ?? "";
const GTAG = process.env["NEXT_PUBLIC_GTAG"] ?? "";
const JOINUS_DESKTOP = process.env["NEXT_PUBLIC_JOINUS_DESKTOP"] ?? "";
const JOINUS_MOBILE = process.env["NEXT_PUBLIC_JOINUS_MOBILE"] ?? "";
const SLACK_API_ENDPOINT = process.env["NEXT_SLACK_API_ENDPOINT"];
const SLACK_ICON_URL = process.env["NEXT_SLACK_ICON_URL"];
const SLACK_MARKETBORO_ICON_URL = process.env["NEXT_SLACK_MARKETBORO_ICON_URL"];
const SLACK_CHANNEL = process.env["NEXT_SLACK_CHANNEL"] ?? "";
const SLACK_MARKETBORO_CHANNEL =
  process.env["NEXT_SLACK_MARKETBORO_CHANNEL"] ?? "";
const SLACK_MONITOR_CHANNEL = process.env["NEXT_SLACK_MONITOR_CHANNEL"] ?? "";
const COOKIE_PREFIX = process.env["NEXT_PUBLIC_COOKIE_PREFIX"];
const COOKIE_DOMAIN = process.env["NEXT_PUBLIC_COOKIE_DOMAIN"];
const VENDOR_URL = process.env["NEXT_PUBLIC_VENDOR_URL"];
const SHOP_URL = process.env["NEXT_PUBLIC_SHOP_URL"];
const WHOLESALESVENDOR_URL = process.env["NEXT_PUBLIC_WHOLESALESVENDOR_URL"];
const CHANNELIO_SDK_KEY = process.env["NEXT_PUBLIC_CHANNELIO_SDK_KEY"];
const APP_VERSION = process.env["NEXT_PUBLIC_APP_VERSION"] ?? "D.E.V";
const APP_ENV = process.env["NEXT_PUBLIC_APP_ENV"] ?? "D.E.V";
const DATADOG_ENABLED = process.env["NEXT_PUBLIC_DATADOG_ENABLED"] === "TRUE";
const BSS_ENTRYPOINT = process.env["NEXT_PUBLIC_BSS_ENTRYPOINT"] ?? "";
const BSS_SECRET = process.env["NEXT_PUBLIC_BSS_SECRET"] ?? "";

export const Env = {
  GROWTHBOOK_API_HOST,
  GROWTHBOOK_CLIENT_KEY,
  GTAG,
  JOINUS_DESKTOP,
  JOINUS_MOBILE,
  SLACK_API_ENDPOINT,
  SLACK_ICON_URL,
  SLACK_MARKETBORO_ICON_URL,
  SLACK_CHANNEL,
  SLACK_MARKETBORO_CHANNEL,
  SLACK_MONITOR_CHANNEL,
  COOKIE_PREFIX,
  COOKIE_DOMAIN,
  VENDOR_URL,
  SHOP_URL,
  WHOLESALESVENDOR_URL,
  CHANNELIO_SDK_KEY,
  APP_VERSION,
  APP_ENV,
  DATADOG_ENABLED,
  BSS_ENTRYPOINT,
  BSS_SECRET,
};
